body {
  margin: 0;
}

#chatIframe {
  right: 32px !important;
  bottom: 8px !important;
}

@media screen and (max-width: 800px) {
  #clgo {
    max-height: 100vh !important;
    position: fixed !important;
  }
  #chatIframe {
    right: 0px !important;
    bottom: 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  iframe {
    max-height: 99vh !important;
  }
}

.slick-dots {
  & li.slick-active button:before {
    color: #1c7ad9 !important;
    font-size: 14px !important;
    @media (max-width: 899px) {
      font-size: 8px !important;
    }
  }
  & li button:before {
    color: #ababab !important;
    font-size: 14px !important;

    @media (max-width: 899px) {
      font-size: 8px !important;
    }
  }
}

.slick-list {
  @media only screen and (min-width: 899px) {
    margin: 30px !important;
  }
}

.slick-slider,
.slick-initialized {
  width: 100%;

  @media only screen and (max-width: 899px) {
    width: 100vw;
  }
}
